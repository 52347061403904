.dashboard {
}

.home {
}

.login {
}

.twoSplit {
  &__authOne {
    @media screen and (max-width: 768px) {
      background-size: 100% auto;
      height: 800px;
      padding-top: 50px;
    }
    @media screen and (max-width: 640px) {
      height: 600px;
      padding-top: 10px;
    }
  }
  &__auth {
    background-repeat: no-repeat;
    background-size: 100% auto;
  }

  &__auth {
    background-image: linear-gradient(
      180deg,
      rgba(152, 11, 128, 0) 0%,
      #980b80 83.33%
    );
    // url("");
    background-repeat: no-repeat;
    background-size: 100% auto;
  }
}
