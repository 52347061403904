body {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* *::-webkit-scrollbar-track {
  background: #f5f5f5;
} */

*::-webkit-scrollbar-thumb {
  background-color: #d4dcdf;
  border-radius: 20px;
  border: 2px solid #d9d9d9;
}

.scroller ::-webkit-scrollbar {
  width: 5px !important;
  height: 0px !important;
}

.scroller:hover ::-webkit-scrollbar {
  width: 5px !important;
  height: 5px !important;
}

.hide-scroller::-webkit-scrollbar {
  width: 5px !important;
  height: 0px !important;
}

input[type="checkbox"]:checked {
  background: brown;
  color: white;
}

/* Format the overlfow of Flowbite table */
[data-testid="table-element"] {
  overflow: visible; /* Hide scrollbars */
}

.Toastify__toast {
  position: relative !important;
  box-sizing: border-box !important;
  margin-bottom: 1rem !important;
  padding: 16px !important;
  min-height: auto !important;
  /* color: #3BB54A !important; */
  font-family: "Inter", sans-serif;
  /* background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.5)
    ),
    #e0f8f6 !important;
  border: 1px solid #E8F5E9 !important; */
  border-radius: 8px !important;
  box-shadow: none !important;
  display: flex !important;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.54px;
  justify-content: center !important;
  align-items: center !important;
  max-height: 90vh !important;
  overflow: auto !important;
  /* max-width: calc(100vw) !important; */
  max-width: calc(100vw - 366px) !important;
  margin: 0 0 1rem auto !important;

  @media only screen and (max-width: 768px) {
    max-width: calc(100vw) !important;
  }
}

.Toastify__toast--error {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.5)
    ),
    #f9ecef !important;
  border: 1px solid #f9ecef !important;
  color: #a90836 !important;
}
.Toastify__toast--success {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.5)
    ),
    #e0f8f6 !important;
  border: 1px solid #e8f5e9 !important;
  color: #3bb54a !important;
}

/* .Toastify__toast--warning {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.5)
    ),
    #e6dac8 !important;
  border: 1px solid #f1c40f !important;
  color: #f1c40f!important;
} */

.Toastify__toast-container {
  top: 40 !important;
  /* left: 0 !important; */
  right: 0 !important;
  padding: 0 !important;
  /* width: 100% !important; */
}

.Toastify__close-button {
  color: #000000 !important;
  opacity: 1 !important;
}

.Toastify__close-button > svg {
  fill: #000000 !important;

  & > path {
    fill: #000000 !important;
  }
}
